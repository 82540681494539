<script>
import router from "../../index";
export default {
    created() {
        // var roles = localStorage.roles;
        localStorage.session = null;
        localStorage.session_nama = null;
        localStorage.access_token = null;
        localStorage.reload_already = null;
        localStorage.dateLogin = null;
        localStorage.roles = null;

        localStorage.removeItem("session");
        localStorage.removeItem("session_nama");
        localStorage.removeItem("access_token");
        localStorage.removeItem("reload_already");
        localStorage.removeItem("dateLogin");
        localStorage.removeItem("roles");
        router.push("/");
        // alert(roles);
        // if (roles == "pemohon") {
        //   window.location.href =
        //     "https://perizinan.pertanian.go.id/portal-simpel/public/pemohon/dashboard";
        // } else {
        //   window.location.href =
        //     "https://perizinan.pertanian.go.id/portal-simpel/public/monitor";
        // }
    },
};
</script>
